@import "~/styles/responsive";

.demo_video {
  padding: 3rem 0;
  background: linear-gradient(180deg, #f8f9fc 0%, #fff 100%),
    linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0) 100%);

  @include devices(lg) {
    padding: 4.5rem 0;
  }

  .description {
    font-size: 1rem;
    line-height: 150%;
    color: var(--grey-600);

    max-width: 608px;
    margin: 0 auto;
    text-align: center;
  }

  .video_container {
    width: 100%;
    aspect-ratio: calc(16 / 9);
    margin: auto;
    position: relative;

    .video {
      width: 100%;
      aspect-ratio: calc(16 / 9);
      object-fit: cover;
      object-position: center;
      background: lightgray -5.133px -1.996px / 100.69% 101.032% no-repeat;

      @include devices(md) {
        border-radius: 1.5rem;
        box-shadow: 16px 16px 0px 0px rgba(0, 0, 0, 0.1);
      }
    }
  }

  .demo_banner_wrapper {
    padding: 0 1rem;
    max-width: 1240px;
    margin: auto;
  }

  .demo_banner {
    position: relative;
    overflow: hidden;
    border-radius: 1rem;
    padding: 1.25rem;
    background-color: var(--primary-blue-50);
    display: flex;
    flex-direction: column;
    gap:1rem;
    width: 100%;
    margin-top: 1.5rem;

    @include devices(md) {
      padding: 3rem;
      margin-top: 2rem;
      flex-direction: row;
      border: 2px solid #202225;
      box-shadow: 12px 12px 0px 0px #C8D8FF;
      background-image: url('../../../../public/images/demo_banner_backgroud.jpg');
    }

    .demo_banner_title {
      z-index: 2;
      color: var(--primary);
      font-size: 1.25rem;
      line-height: 1.875rem;

      @include devices(md) {
        font-size: 1.5rem;
        line-height: 2.25rem;
      }
    }

    .demo_banner_button {
      z-index: 2;
      font-size: 1rem;
      line-height: 1.5rem;
    }

    .demo_banner_image {
      z-index: 1;
      position: absolute;
      bottom: 0;
      right: 0;
    }

    .mobile {
      display: block;
      @include devices(md) {
        display: none;
      }
    }

    .desktop {
      display: none;
      @include devices(md) {
        display: block;
      }
    }

  }
}
