@import "~/styles/responsive.scss";

.customer_feedback {
  position: relative;
  background: var(--primary-blue-50);
  padding: 3rem 0;
  overflow: hidden;

  @include devices(md) {
    padding: 100px 0;
  }

  .title {
    font-size: 1.25rem;
    font-weight: 600;

    @include devices(md) {
      font-size: 2rem;
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;

    @include devices(md) {
      gap: 3rem;
    }

    .feedbacks {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      @include devices(md) {
        display: grid;
        grid-template-areas:
          "f1 f2 f3"
          "f1 f2 f6"
          "f1 f5 f6"
          "f4 f5 f6"
          "f7 f5 f6"
          "f7 f5 f8"
          "f7 f5 f10"
          "f7 f9 f10";
      }
    }
  }

  .blur {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    height: 296px;
    background: linear-gradient(
      180deg,
      rgba(230, 236, 251, 0) 0%,
      var(--primary-blue-50) 85%
    );
    display: none;

    @include devices(md) {
      display: block;
    }
  }
}

.feedback {
  padding: 1rem;
  border-radius: 1.25rem;
  background: white;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  border: 3px solid var(--grey-800);
  letter-spacing: -0.1px;
  max-width: 384px;
  white-space: pre-line;
  box-shadow: 12px 12px 0px 0px #c8d8ff;

  .divider {
    width: 100%;
    height: 1px;
    background: var(--grey-100);
  }

  .description {
    color: var(--grey-600);
    font-size: 0.875rem;
    line-height: 1.5;
  }
}
