@import "~/styles/responsive.scss";

.pricing {
  background: white;
  padding: 2.5rem 0;

  @include devices(lg) {
    padding: 4.25rem 0;
  }

  .pricing_wrapper {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    border-radius: 20px;
    gap:1.25rem;

    @include devices(lg) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      gap: 0;
      border: 3px solid var(--grey-800);
    }
  }

  .package_item_container {
    position: relative;
    z-index: 0;

    &:first-child {
      .package_item {
        border-radius: 1.25rem;
      }
    }

    &:nth-child(2) {
      .package_item {
        border-top: none;
        border-bottom: none;
      }
    }

    &:last-child {
      .package_item {
        border-radius: 1.25rem;
      }
    }

    @include devices(lg) {
      &:first-child {
        .package_item {
          border-radius: 1.25rem 0 0 1.25rem;
        }
      }

      &:nth-child(3) {
        .package_item {
          border-radius: 0;
        }

        &::before {
          border-radius: 0;
        }
      }

      &:last-child {
        .package_item {
          border-radius: 0 1.25rem 1.25rem 0;
        }
      }
    }

    &:nth-child(3) {
      @include devices(lg) {
        padding-top: 0;
      }
    }

    &.premium {
      &::before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute; 
        top: 0;
        background: var(--primary);
        z-index: 2;
        border-radius: 12px;

        @include devices(lg) {
          height: calc(100% + 6px);
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        }
        
      }
    }
  }

  .package_item {
    --name-color: var(--accent-green);
    --box-shadow-color: rgba(142, 229, 177, 0.33);

    position: relative;
    display: flex;
    flex-direction: column;
    background-color: white;
    z-index: 1;
    height: 100%;
    border: 3px solid var(--grey-800);
    padding: 24px;
    gap: 32px;

    @include devices(lg) {
      border: none;
    }

    &.pro {
      --name-color: #3e70dd;
      --box-shadow-color: rgba(189, 192, 255, 0.66);
    }

    &.premium {
      --name-color: #fc8b23;
      --box-shadow-color: #ffdf94;
      position: relative;
      background: white;
      z-index: 2;
      width: calc(100% - 6px);
      margin: auto;
      border: none;
      height: calc(100% - 6px);
      border-radius: 0.5rem;
      padding-top: calc(1.75rem + 24px);

      @include devices(lg) {
        padding-top: 24px;
        height: 100%;
      }

      .header {
        border-radius: 1rem;
      }
    }

    &.enterprise {
      --name-color: #702dff;
      --box-shadow-color: rgba(191, 86, 255, 0.33);
    }

    @include devices(lg) {
      &:first-child {
        .header {
          border-top-left-radius: 1rem;
          border-bottom-left-radius: 1rem;
        }
      }

      &:last-child {
        .header {
          border-top-right-radius: 1rem;
          border-bottom-right-radius: 1rem;
        }
      }
    }

    .header {
      display: flex;
      flex-direction: column;
      gap: 24px;
      z-index: 5;
    }

    .most_choice {
      position: absolute;
      background-color: var(--primary);
      display: flex;
      padding: 0.25rem 1rem 0.25rem 0.75rem;
      align-items: center;
      justify-content: center;
      z-index: 6;
      gap: 4px;
      left: -3px;
      top: 0;
      width: calc(100% + 6px);
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      
      @include devices(lg) {
        top: -1.75rem;
      }

    }

    .name {
      color: var(--name-color);
      font-size: 1.5rem;
      font-weight: 600;
    }

    .currency {
      font-weight: 700;
      font-size: 1.75rem;

      @include devices(lg) {
        font-size: 2rem;
      }
    }

    .price {
      font-weight: 700;
      font-size: 1.5rem;
    }

    .period {
      color: var(--grey-600);
      font-size: 0.875rem;
    }

    .lets_talk {
      font-weight: 700;
      font-size: 1.5rem;
    }

    .benefit {
      color: var(--grey-700);
      font-size: 0.875rem;
      display: flex;
      gap: 0.5rem;
      padding-bottom: 0.75rem;
      position: relative;

      &:last-child {
        &:after {
          background-color: transparent;
        }
      }

      &.enterprise_special {
        background: var(
          --gradient-linear-purple,
          linear-gradient(109deg, #d663ff -17.03%, #043dd0 138.12%)
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      &:before {
        content: "";
        width: 20px;
        height: 20px;
        background: var(--name-color);
        clip-path: path(
          "M8.83464 11.4993L7.02214 9.68685C6.86936 9.53407 6.68186 9.45768 6.45964 9.45768C6.23741 9.45768 6.04297 9.54102 5.8763 9.70768C5.72352 9.86046 5.64714 10.0549 5.64714 10.291C5.64714 10.5271 5.72352 10.7216 5.8763 10.8743L8.2513 13.2493C8.40408 13.4021 8.59852 13.4785 8.83464 13.4785C9.07075 13.4785 9.26519 13.4021 9.41797 13.2493L14.1471 8.52018C14.2999 8.3674 14.3763 8.1799 14.3763 7.95768C14.3763 7.73546 14.293 7.54102 14.1263 7.37435C13.9735 7.22157 13.7791 7.14518 13.543 7.14518C13.3069 7.14518 13.1124 7.22157 12.9596 7.37435L8.83464 11.4993ZM10.0013 18.3327C8.84852 18.3327 7.76519 18.1138 6.7513 17.676C5.73741 17.2382 4.85547 16.6446 4.10547 15.8952C3.35547 15.1452 2.76186 14.2632 2.32464 13.2493C1.88741 12.2355 1.66852 11.1521 1.66797 9.99935C1.66797 8.84657 1.88686 7.76324 2.32464 6.74935C2.76241 5.73546 3.35602 4.85352 4.10547 4.10352C4.85547 3.35352 5.73741 2.7599 6.7513 2.32268C7.76519 1.88546 8.84852 1.66657 10.0013 1.66602C11.1541 1.66602 12.2374 1.8849 13.2513 2.32268C14.2652 2.76046 15.1471 3.35407 15.8971 4.10352C16.6471 4.85352 17.241 5.73546 17.6788 6.74935C18.1166 7.76324 18.3352 8.84657 18.3346 9.99935C18.3346 11.1521 18.1157 12.2355 17.678 13.2493C17.2402 14.2632 16.6466 15.1452 15.8971 15.8952C15.1471 16.6452 14.2652 17.2391 13.2513 17.6768C12.2374 18.1146 11.1541 18.3332 10.0013 18.3327Z"
        );
        flex-shrink: 0;
      }

      &:after {
        content: "";
        background-color: var(--primary-blue-50);
        height: 1px;
        width: calc(100% - 0.5rem - 20px);
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }
  }
}

.tooltip {
  border-radius: 8px !important;
  background: var(--grey-800) !important;
  color: var(--grey-100) !important;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  padding-left: 8px !important;
}

.life_time_container {
  border-radius: 20px;
  border: 3px solid var(--grey-800);
  background: var(--accent-yellow-50);
  padding: 28px 1.25rem;
  margin-top: 3rem;
  font-size: 0.875rem;
  color: var(--grey-800);
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;

  @include devices(lg) {
    padding: 3rem;
  }

  &::before {
    content: "";
    background: url("/svgs/appsumo_icon.svg");
    background-size: cover;
    width: 302px;
    aspect-ratio: calc(302 / 200);
    position: absolute;
    right: -1rem;
    bottom: 0;
  }

  .deal_container {
    background-color: white;
    width: 100%;
    border: 2px solid var(--grey-800);
    border-radius: 1rem;

    .deal_item {
      padding: 1.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
      border-bottom: 2px solid var(--grey-800);

      &:last-child {
        border-bottom: none;
      }

      @include devices(md) {
        border-right: 2px solid var(--grey-800);

        &:nth-child(2),
        &:nth-child(3) {
          border-right: none;
        }
      }

      @include devices(lg) {
        border-right: 2px solid var(--grey-800);
        border-bottom: none;

        &:nth-child(2) {
          border-right: 2px solid var(--grey-800);
        }

        &:last-child {
          border-right: none;
        }
      }

      .deal_name {
        font-size: 1.25rem;
      }

      .deal_price {
        display: flex;
        align-items: center;
        gap: 0.25rem;
        color: var(--grey-600);
        font-size: 1rem;
        font-weight: 400;
        line-height: 150%;

        strong {
          color: var(--grey-800);
          font-size: 1.5rem;
          font-weight: 700;
          text-decoration: unset;
        }

        span {
          text-decoration: line-through;
          font-size: 0.875rem;
        }
      }
    }
  }

  .deal_name {
    font-size: 1.5rem;
    line-height: 150%;
    font-weight: 600;
  }

  .deal_terms {
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;

    div {
      font-weight: 600;
      line-height: 150%;
      text-decoration: underline;
    }
  }

  .benefit {
    color: var(--grey-700);
    font-size: 0.875rem;
    display: flex;
    gap: 0.5rem;
    padding: 0.75rem 0;
    position: relative;
    line-height: 150%;

    &:last-child {
      &:after {
        background-color: transparent;
      }
      padding-bottom: 0;
    }

    &:before {
      content: "";
      width: 20px;
      height: 20px;
      background: #ffb130;
      clip-path: path(
        "M8.83464 11.4993L7.02214 9.68685C6.86936 9.53407 6.68186 9.45768 6.45964 9.45768C6.23741 9.45768 6.04297 9.54102 5.8763 9.70768C5.72352 9.86046 5.64714 10.0549 5.64714 10.291C5.64714 10.5271 5.72352 10.7216 5.8763 10.8743L8.2513 13.2493C8.40408 13.4021 8.59852 13.4785 8.83464 13.4785C9.07075 13.4785 9.26519 13.4021 9.41797 13.2493L14.1471 8.52018C14.2999 8.3674 14.3763 8.1799 14.3763 7.95768C14.3763 7.73546 14.293 7.54102 14.1263 7.37435C13.9735 7.22157 13.7791 7.14518 13.543 7.14518C13.3069 7.14518 13.1124 7.22157 12.9596 7.37435L8.83464 11.4993ZM10.0013 18.3327C8.84852 18.3327 7.76519 18.1138 6.7513 17.676C5.73741 17.2382 4.85547 16.6446 4.10547 15.8952C3.35547 15.1452 2.76186 14.2632 2.32464 13.2493C1.88741 12.2355 1.66852 11.1521 1.66797 9.99935C1.66797 8.84657 1.88686 7.76324 2.32464 6.74935C2.76241 5.73546 3.35602 4.85352 4.10547 4.10352C4.85547 3.35352 5.73741 2.7599 6.7513 2.32268C7.76519 1.88546 8.84852 1.66657 10.0013 1.66602C11.1541 1.66602 12.2374 1.8849 13.2513 2.32268C14.2652 2.76046 15.1471 3.35407 15.8971 4.10352C16.6471 4.85352 17.241 5.73546 17.6788 6.74935C18.1166 7.76324 18.3352 8.84657 18.3346 9.99935C18.3346 11.1521 18.1157 12.2355 17.678 13.2493C17.2402 14.2632 16.6466 15.1452 15.8971 15.8952C15.1471 16.6452 14.2652 17.2391 13.2513 17.6768C12.2374 18.1146 11.1541 18.3332 10.0013 18.3327Z"
      );
      flex-shrink: 0;
    }

    &:after {
      content: "";
      background-color: white;
      height: 1px;
      width: calc(100% - 0.5rem - 20px);
      position: absolute;
      bottom: 0;
      right: 0;
      opacity: 0.5;
    }
  }
}

.appsumo_modal {
  position: relative;
  max-width: 820px;
  width: 100%;
  padding: 1rem;
  max-height: 100%;
  overflow-y: auto;
  outline: none;
  border: none;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  right: auto;
  bottom: auto;

  .header {
    position: relative;
    padding: 1.5rem;

    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    background-color: var(--accent-yellow-50);
    border-top-left-radius: 1.25rem;
    border-top-right-radius: 1.25rem;

    &::before {
      content: "";
      background: url("/svgs/appsumo_icon.svg");
      background-size: cover;
      width: 214px;
      aspect-ratio: calc(214 / 161);
      position: absolute;
      left: 50%;
      bottom: -3rem;
      transform: translateX(-35%);
    }

    .sub_name {
      display: flex;
      gap: 0.25rem;
      align-items: center;
      font-size: 0.875rem;
      color: black;
    }

    .name {
      font-size: 1.5rem;
      font-weight: 600;
      color: var(--grey-800);
    }

    .discount_price {
      font-size: 2rem;
      font-weight: bold;
      color: var(--grey-800);
    }

    .origin_price {
      font-size: 1rem;
      color: var(--grey-600);
      text-decoration: line-through;
    }

    .close_icon {
      position: absolute;
      top: 1rem;
      right: 1rem;
      width: 1.5rem;
      height: 1.5rem;
      cursor: pointer;
      border-radius: 0.25rem;

      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.25s ease-in-out;

      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
  }

  .content {
    display: flex;
    padding: 1.5rem;
    gap: 1.25rem;
    background-color: white;
    border-bottom-left-radius: 1.25rem;
    border-bottom-right-radius: 1.25rem;
    flex-direction: column;

    @include devices(md) {
      flex-direction: row;
    }

    .line {
      width: 100%;
      height: 1px;
      opacity: 0.5;
      background-color: var(--grey-100);
    }

    .title {
      font-size: 1rem;
      color: var(--grey-800);
      font-weight: 600;
    }

    .terms {
      flex: 1;

      display: flex;
      flex-direction: column;
      row-gap: 1rem;

      .list {
        display: flex;
        flex-direction: column;
        row-gap: 0.75rem;

        .item {
          position: relative;
          display: flex;
          gap: 0.75rem;

          .text {
            font-size: 0.875rem;
            color: var(--grey-600);
            line-height: 1.5;
          }

          &::before {
            flex-shrink: 0;
            content: "";
            width: 1.25rem;
            height: 1.25rem;
            background-color: var(--accent);
            clip-path: path(
              "M8.83464 11.4993L7.02214 9.68685C6.86936 9.53407 6.68186 9.45768 6.45964 9.45768C6.23741 9.45768 6.04297 9.54102 5.8763 9.70768C5.72352 9.86046 5.64714 10.0549 5.64714 10.291C5.64714 10.5271 5.72352 10.7216 5.8763 10.8743L8.2513 13.2493C8.40408 13.4021 8.59852 13.4785 8.83464 13.4785C9.07075 13.4785 9.26519 13.4021 9.41797 13.2493L14.1471 8.52018C14.2999 8.3674 14.3763 8.1799 14.3763 7.95768C14.3763 7.73546 14.293 7.54102 14.1263 7.37435C13.9735 7.22157 13.7791 7.14518 13.543 7.14518C13.3069 7.14518 13.1124 7.22157 12.9596 7.37435L8.83464 11.4993ZM10.0013 18.3327C8.84852 18.3327 7.76519 18.1138 6.7513 17.676C5.73741 17.2382 4.85547 16.6446 4.10547 15.8952C3.35547 15.1452 2.76186 14.2632 2.32464 13.2493C1.88741 12.2355 1.66852 11.1521 1.66797 9.99935C1.66797 8.84657 1.88686 7.76324 2.32464 6.74935C2.76241 5.73546 3.35602 4.85352 4.10547 4.10352C4.85547 3.35352 5.73741 2.7599 6.7513 2.32268C7.76519 1.88546 8.84852 1.66657 10.0013 1.66602C11.1541 1.66602 12.2374 1.8849 13.2513 2.32268C14.2652 2.76046 15.1471 3.35407 15.8971 4.10352C16.6471 4.85352 17.241 5.73546 17.6788 6.74935C18.1166 7.76324 18.3352 8.84657 18.3346 9.99935C18.3346 11.1521 18.1157 12.2355 17.678 13.2493C17.2402 14.2632 16.6466 15.1452 15.8971 15.8952C15.1471 16.6452 14.2652 17.2391 13.2513 17.6768C12.2374 18.1146 11.1541 18.3332 10.0013 18.3327Z"
            );
          }
        }
      }
    }

    .conditions {
      flex: 1;

      display: flex;
      flex-direction: column;
      row-gap: 1rem;

      .list {
        display: flex;
        flex-direction: column;
        row-gap: 0.75rem;

        .item {
          display: flex;
          gap: 0.75rem;

          .text {
            font-size: 0.875rem;
            color: var(--grey-600);
            line-height: 1.5;
          }
        }
      }
    }
  }
}
