@import "~/styles/responsive";

.features {
  background-color: var(--grey-900);
  color: white;
  overflow: hidden;

  .container {
    display: flex;
    flex-direction: column;
    padding: 4rem 1rem;
    gap:4rem;

    @include devices(lg) {
      padding: 6.25rem;
    }
  }

  :global(.section-title) {
    color: white;
  }

  .title {
    font-size: 1.5rem;
    line-height: 2.25rem;

    @include devices(md) {
        text-align: center;
        font-size: 2rem;
        line-height: 2.42rem;
    }
  }

  .description {
    font-size: 1.125rem;
    line-height: 1.6875rem;
    color: var(--grey-200);
    text-align: left;

    strong {
      color: var(--accent-yellow);
    }

    @include devices(md) {
        color: white;
        font-size: 1.25rem;
        line-height: 1.875rem;
        text-align: center;
    }
  }


  .items_container {
   display: flex;
   flex-direction: column;
   gap: 1.5rem;

   @include devices(lg) {
      gap: 4rem;
    }
  }

  .left {
    flex-direction: column-reverse;

    @include devices(lg) {
      flex-direction: row;
    }
  }

  .right {
    flex-direction: column-reverse;

    @include devices(lg) {
      flex-direction: row-reverse;
    }
  }

  .section {
    display: flex;
    align-items: center;
    border: 1px solid #36393F;
    border-radius: 1rem;

    @include devices(lg) {
      border: unset;
    }

    .image {
      border-radius: 1rem;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      @include devices(lg) {
        border-radius: 1rem;
      }
    }


    .info {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      width: 100%;
      padding: 1.25rem;
      gap:0.5rem;

      @include devices(lg) {
        padding: 0 4.5rem;
        gap: 1.5rem
      }

      .info_title {
        font-size: 1.25rem;
        line-height: 1.875rem;

        @include devices(lg) {
          font-size: 1.5rem;
          line-height: 2.25rem;
        }
      }

      .info_description {
        color:var(--grey-200);
        font-size: 0.875rem;
        line-height: 1.3125rem;
      }

    }

  }

  .integration {
    border-radius: 20px;
    background: var(--grey-700);
    padding: 1.5rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 2.5rem;

    @include devices(md) {
      padding: 3rem 2.5rem;
      flex-direction: row;
      align-items: center;
    }

    .integration_text_container {
      display: flex;
      flex: 1;
      flex-direction: column;
      gap:2.5rem;

      @include devices(md) {
        gap: 3rem
      }
    }

    .integration_cta {
      background-color: #202225;
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 0.75rem;
      padding: 1.5rem 0;
      border-radius: 1rem;
      align-items: center;

      .integration_cta_title {
        font-size: 1.25rem;
        line-height: 1.875rem;

        @include devices(lg) {
          font-size: 1.5rem;
          line-height: 2.25rem;
        }
      }

      .integration_cta_description {
        color: var(--grey-200);
        font-size: 0.875rem;
        line-height: 1.3125rem;

        @include devices(lg) {
          font-size: 1rem;
          line-height: 1.5rem;
        }

        strong {
          color: var(--accent-yellow);
        }
      }

    }

    .integration_title {
      font-size: 1.25rem;
      font-weight: 600;
      line-height: 1.875rem;
      color: white;
      max-width: 500px;

      @include devices(lg) {
        font-size: 2rem;
        line-height: 2.42rem;
      }
    }

    .integration_desc {
      font-size: 0.875rem;
      line-height: 1.3125rem;
      color: var(--grey-200);

      @include devices(lg) {
        font-size: 1rem;
        line-height: 1.5rem;
      }
    }
  }
}
